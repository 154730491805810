import React, { useState } from 'react';

import FormikField from '@/components/shared/FormikField';
import {
	useDeleteSongMutation,
	useListSongsQuery,
	useMergeSongsMutation,
	useReanalyzeSongsMutation,
} from '@/graphql';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
	Box,
	Button,
	ButtonGroup,
	Checkbox,
	Flex,
	FormControl,
	FormLabel,
	Input,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Spinner,
	Text,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';

import CreateSongModal, {
	CreateSongInitialValues,
} from './components/CreateSongModal';
import SongCard from './components/SongCard';

const Songs = () => {
	const { data, error, isLoading, refetch } = useListSongsQuery();
	const { mutateAsync } = useDeleteSongMutation({
		onSuccess: () => {
			refetch();
		},
	});
	const { mutateAsync: mergeSongs, isLoading: isMergeLoading } =
		useMergeSongsMutation({
			onSuccess: () => {
				refetch();
			},
		});

	const { mutateAsync: reanalyzeSongs, isLoading: isReanalyzeLoading } =
		useReanalyzeSongsMutation({
			onSuccess: () => {
				refetch();
			},
		});

	const { isOpen, onOpen, onClose } = useDisclosure();
	const toast = useToast();
	const [initialData, setInitialData] = useState<CreateSongInitialValues>();
	const [searchParam, setSearchParam] = useState('');
	const [withLyrics, setWithLyrics] = useState(false);
	const [onlySelected, setOnlySelected] = useState(false);
	const [viewAs, setViewAs] = useState<'grid' | 'rows'>('rows');
	const [selectedSongs, setSelectedSongs] = useState<string[]>([]);

	const mergeSelectedSongs = async () => {
		await mergeSongs({
			songIds: selectedSongs.map((id) => id),
		});
		setSelectedSongs([]);
	};

	if (isLoading) {
		return <Spinner />;
	}

	if (error || !data) {
		throw error;
	}

	return (
		<>
			<CreateSongModal
				isOpen={isOpen}
				onClose={onClose}
				onSuccess={refetch}
				initialData={initialData}
			/>
			<Flex justifyContent="center" gap="10px">
				<Button
					display="block"
					my="50px"
					flexShrink={0}
					isLoading={isReanalyzeLoading}
					onClick={async () => {
						const reanalyzeSongsRes = await reanalyzeSongs({});
						toast({
							title: `${reanalyzeSongsRes.reanalyzeSongs} songs reanalyzed successfully!`,
							status: 'success',
						});
					}}
				>
					Reanalyze songs
				</Button>
				<Button
					display="block"
					my="50px"
					flexShrink={0}
					onClick={() => {
						setInitialData(undefined);
						onOpen();
					}}
				>
					Create song
				</Button>
			</Flex>
			<Flex
				gap={5}
				sx={{ position: 'sticky', top: '0' }}
				bg="white"
				zIndex={100}
				p={5}
			>
				<FormControl flexBasis={1} flexGrow={1} flexShrink={1}>
					<FormLabel>Search param</FormLabel>
					<Input
						placeholder={
							withLyrics ? 'Artist, Name, Lyrics' : 'Artist, Name'
						}
						type={'text'}
						onChange={(e) => setSearchParam(e.currentTarget.value)}
						value={searchParam}
					/>
				</FormControl>

				<FormControl flexGrow={0} flexShrink={1} flexBasis="100px">
					<FormLabel>With Lyrics</FormLabel>
					<Checkbox
						size="lg"
						onChange={(e) => setWithLyrics(e.target.checked)}
						isChecked={withLyrics}
					/>
				</FormControl>

				<FormControl flexGrow={0} flexShrink={1} flexBasis="120px">
					<FormLabel>Only Selected</FormLabel>
					<Checkbox
						size="lg"
						onChange={(e) => setOnlySelected(e.target.checked)}
						isChecked={onlySelected}
					/>
				</FormControl>

				<FormControl flexGrow={0} flexShrink={1} flexBasis="100px">
					<FormLabel>Actions</FormLabel>
					<ButtonGroup>
						<Button
							isDisabled={selectedSongs.length < 2}
							onClick={() => mergeSelectedSongs()}
							isLoading={isMergeLoading}
						>
							Merge ({selectedSongs.length})
						</Button>
						<Button
							isDisabled={!selectedSongs.length}
							onClick={() => setSelectedSongs([])}
						>
							Clear
						</Button>
					</ButtonGroup>
				</FormControl>
			</Flex>
			<Flex gap="20px" mt="30px" alignItems="stretch" flexWrap="wrap">
				{data.listSongs.map((song) => {
					if (!song) {
						return null;
					}

					const songLyrics = (song.lyrics || '').toLowerCase();

					if (searchParam.length > 1) {
						let isMatch =
							song.name
								.toLowerCase()
								.search(searchParam.toLowerCase()) > -1 ||
							song.artist
								.toLowerCase()
								.search(searchParam.toLowerCase()) > -1;

						if (withLyrics && songLyrics.length < 1) {
							isMatch =
								isMatch ||
								songLyrics.search(searchParam.toLowerCase()) >
									-1;
						}

						if (!isMatch) {
							return null;
						}
					}

					if (withLyrics && songLyrics.length < 1) {
						return null;
					}

					const selectOrder =
						selectedSongs.findIndex((sid) => sid === song.id) + 1;

					if (onlySelected && selectOrder < 1) {
						return null;
					}

					return (
						<SongCard
							flexShrink={0}
							key={song.id}
							overlay={selectOrder}
							overlayColor={
								selectOrder === 1 ? 'green.200' : undefined
							}
							w="280px"
							id={song.id}
							songThemes={{
								emotionTags: song.emotionTags,
								energyTags: song.energyTags,
								gospelTags: song.gospelTags,
								songTypeTags: song.songTypeTags,
							}}
							name={song.name}
							artworkUrl={song.artworkUrl}
							artist={song.artist}
							variations={JSON.stringify(song.variations)}
							footer={
								<ButtonGroup spacing="3" w="100%">
									<Button
										variant="outline"
										onClick={() => {
											setInitialData({
												...song,
												analysisOutput:
													song.analysisOutput
														? JSON.stringify(
																song.analysisOutput,
																null,
																2,
														  )
														: '',
												variations: JSON.stringify(
													song.variations,
												),
											});
											onOpen();
										}}
									>
										Edit
									</Button>
									<Popover>
										{({ onClose }) => (
											<>
												<PopoverTrigger>
													<Button
														variant="ghost"
														colorScheme="red"
													>
														Delete
													</Button>
												</PopoverTrigger>
												<PopoverContent>
													<PopoverArrow />
													<PopoverHeader>
														Confirmation!
													</PopoverHeader>
													<PopoverBody>
														<ButtonGroup
															spacing="2"
															w="100%"
														>
															<Button
																variant="solid"
																colorScheme="red"
																onClick={async () => {
																	try {
																		await mutateAsync(
																			{
																				songId: song.id,
																			},
																		);

																		toast({
																			title: `Song ${song.name} deleted!`,
																		});
																		onClose();
																	} catch (err) {
																		console.error(
																			err,
																		);
																		toast({
																			title: 'Error deleting song!',
																			status: 'error',
																			description:
																				err instanceof
																				Error
																					? err.message
																					: 'Unkown error, check console',
																		});
																	}
																}}
															>
																Yes
															</Button>
															<Button
																variant="ghost"
																onClick={
																	onClose
																}
															>
																No
															</Button>
														</ButtonGroup>
													</PopoverBody>
												</PopoverContent>
											</>
										)}
									</Popover>
									<Checkbox
										isChecked={selectedSongs.includes(
											song.id,
										)}
										onChange={(e) => {
											if (e.target.checked) {
												setSelectedSongs([
													...selectedSongs,
													song.id,
												]);
											} else {
												setSelectedSongs(
													selectedSongs.filter(
														(id) => id !== song.id,
													),
												);
											}
										}}
									/>
								</ButtonGroup>
							}
						/>
					);
				})}
			</Flex>
		</>
	);
};

export default Songs;
