import React, { PropsWithChildren } from 'react';

import ErrorInspector from '@/components/shared/ErrorInspector';
import FormikField from '@/components/shared/FormikField';
import {
	SongInput,
	useCreateSongMutation,
	useReanalyzeSongMutation,
	useUpdateSongMutation,
} from '@/graphql';
import { FetchError } from '@/graphql/codegen-fetcher';
import { SongInputSchema } from '@/graphql/validation';
import {
	Button,
	ButtonGroup,
	FormControl,
	FormHelperText,
	FormLabel,
	HStack,
	Image,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Stack,
	Tag,
	Textarea,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { omit } from 'ramda';
import * as yup from 'yup';

import { RenderChips, TagItem } from './RenderChips';

const songInputSchema = yup.object({
	appleMusicId: yup.string().nullable(),
	artist: yup.string().required().max(80),
	artworkUrl: yup.string().nullable(),
	ccli: yup.number().nullable(),
	name: yup.string().required().max(80),
	recommendedKey: yup.string().nullable(),
	recordedKey: yup.string().defined(),
	spotifyId: yup.string().nullable(),
	youtubeId: yup.string().nullable(),
	lyrics: yup.string().nullable(),
	variations: yup.string().nullable(),
});

const FormSectionTitle: React.FC<PropsWithChildren> = ({ children }) => (
	<FormLabel mt={10} fontWeight={800} fontSize={18}>
		{children}
	</FormLabel>
);

const initialValues: Omit<SongInput, 'variations' | 'analysisOutput'> & {
	id: string;
	variations: string;
	analysisOutput?: string | null;
	analyzed?: boolean | null;
} = {
	id: '',
	name: '',
	artworkUrl: '',
	artist: '',
	ccli: null,
	recordedKey: '',
	recommendedKey: '',
	youtubeId: '',
	spotifyId: '',
	appleMusicId: '',
	emotionTags: [],
	energyTags: [],
	gospelTags: [],
	songTypeTags: [],
	variations: '[]',
};
const keys = ['C', 'Db', 'D', 'Eb', 'E', 'F', 'F#', 'G', 'Ab', 'A', 'Bb', 'B'];

export type CreateSongInitialValues = typeof initialValues;

type CreateSongModalProps = {
	isOpen: boolean;
	onClose: () => void;
	onSuccess: () => void;
	initialData?: typeof initialValues;
};

const CreateSongModal: React.FC<CreateSongModalProps> = ({
	isOpen,
	onClose,
	onSuccess,
	initialData = initialValues,
}) => {
	const {
		mutateAsync,
		error: createSongError,
		isLoading: createSongLoading,
	} = useCreateSongMutation<FetchError>({
		onSuccess: onSuccess,
	});
	const {
		mutateAsync: updateMutateAsync,
		error: updateSongError,
		isLoading: updateSongLoading,
	} = useUpdateSongMutation<FetchError>({
		onSuccess: onSuccess,
	});
	const {
		mutateAsync: analyzeMutateAsync,
		error: analyzeSongError,
		isLoading: analyzeSongLoading,
	} = useReanalyzeSongMutation<FetchError>({
		onSuccess: onSuccess,
	});

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					{initialData.id ? 'Update' : 'Create'} Song
				</ModalHeader>
				<ModalCloseButton />
				<Formik
					key="asaphSignUn"
					initialValues={initialData}
					validationSchema={songInputSchema}
					onSubmit={async (values, formikHelpers) => {
						try {
							const input = omit(['id', 'analyzed'], values);
							if (initialData.id) {
								await updateMutateAsync({
									input: {
										...input,
										ccli: values.ccli || null,
										variations: values.variations
											? JSON.parse(values.variations)
											: [],
										analysisOutput: values.analysisOutput
											? JSON.parse(values.analysisOutput)
											: [],
									},
									songId: initialData.id,
								});
							} else {
								await mutateAsync({
									input: {
										...input,
										variations: values.variations
											? JSON.parse(values.variations)
											: [],
										analysisOutput: values.analysisOutput
											? JSON.parse(values.analysisOutput)
											: [],
									},
								});
							}
							onClose();
						} catch (err) {
							console.error(err);
						} finally {
							formikHelpers.setSubmitting(false);
						}
					}}
				>
					{({
						handleSubmit,
						handleChange,
						values,
						errors,
						setFieldValue,
					}) => {
						const energyTagsMap: TagItem[] = [
							{ label: 'upbeat' },
							{ label: 'building' },
							{ label: 'settling' },
							{ label: 'reflective' },
						].map((item) => ({
							...item,
							selected: values.energyTags?.includes(item.label),
						}));
						const emotionTagsMap: TagItem[] = (
							[
								{ label: 'fear' },
								{ label: 'anger' },
								{ label: 'worry' },
								{ label: 'sadness' },
								{ label: 'peace' },
								{ label: 'contentment' },
								{ label: 'joy' },
								{ label: 'love' },
							] as TagItem[]
						).map((item) => ({
							...item,
							selected: values.emotionTags?.includes(item.label),
						}));
						const gospelTagsMap: TagItem[] = [
							{ label: 'glory of god' },
							{ label: 'gravity of sin' },
							{ label: 'grandeur of grace' },
						].map((item) => ({
							...item,
							selected: values.gospelTags?.includes(item.label),
						}));
						const songTypeTagsMap: TagItem[] = [
							{ label: 'chorus' },
							{ label: 'hymn' },
						].map((item) => ({
							...item,
							selected: values.songTypeTags?.includes(item.label),
						}));

						return (
							<form onSubmit={handleSubmit}>
								<ModalBody>
									<FormControl>
										<Stack spacing={4}>
											<FormSectionTitle>
												Song details
											</FormSectionTitle>

											<FormikField
												type="text"
												name="name"
												label="Song name"
												handleChange={handleChange}
												value={values.name}
												isRequired
											/>
											<FormikField
												type="text"
												name="artist"
												label="Artist"
												handleChange={handleChange}
												value={values.artist}
												isRequired
											/>
											<FormikField
												type="number"
												name="ccli"
												label="CCLI #"
												handleChange={handleChange}
												value={values.ccli}
											/>
											<FormikField
												type="text"
												name="artworkUrl"
												label="Artwork Url"
												handleChange={handleChange}
												value={values.artworkUrl}
											/>
											<Image
												src={values.artworkUrl ?? ''}
												fallbackSrc="https://via.placeholder.com/200"
												h={200}
												w={200}
											/>
										</Stack>
									</FormControl>

									<FormControl>
										<Stack spacing={4}>
											<FormSectionTitle>
												Song themes
											</FormSectionTitle>

											<FormLabel mt={10}>
												Energy (Select One)
											</FormLabel>

											<RenderChips
												items={energyTagsMap}
												onChange={(
													_i,
													label,
													value,
												) => {
													const newState = !value;
													setFieldValue(
														'energyTags',
														newState ? [label] : [],
													);
												}}
											/>

											<FormLabel mt={10}>
												Emotional (Select One or many)
											</FormLabel>

											<RenderChips
												items={emotionTagsMap}
												onChange={(
													_i,
													label,
													value,
												) => {
													const newState = !value;
													let tags =
														values.emotionTags ||
														[];
													if (newState) {
														tags = [...tags, label];
													} else {
														tags = tags.filter(
															(i) => i !== label,
														);
													}
													setFieldValue(
														'emotionTags',
														tags,
													);
												}}
											/>

											<FormLabel mt={10}>
												Gospel Story (Select One or
												many)
											</FormLabel>

											<RenderChips
												items={gospelTagsMap}
												onChange={(
													_i,
													label,
													value,
												) => {
													const newState = !value;
													let tags =
														values.gospelTags || [];
													if (newState) {
														tags = [...tags, label];
													} else {
														tags = tags.filter(
															(i) => i !== label,
														);
													}
													setFieldValue(
														'gospelTags',
														tags,
													);
												}}
											/>

											<FormLabel mt={10}>
												Song type (Select One)
											</FormLabel>

											<RenderChips
												items={songTypeTagsMap}
												onChange={(
													_i,
													label,
													value,
												) => {
													const newState = !value;
													setFieldValue(
														'songTypeTags',
														newState ? [label] : [],
													);
												}}
											/>
										</Stack>
									</FormControl>
									<FormControl>
										<Stack spacing={4}>
											<FormSectionTitle>
												Listen details
											</FormSectionTitle>

											<FormikField
												type="text"
												name="spotifyId"
												label="Spotify ID"
												handleChange={handleChange}
												value={values.spotifyId}
											/>
											<FormHelperText>
												https://open.spotify.com/track/
											</FormHelperText>
											<FormikField
												type="text"
												name="youtubeId"
												label="YouTube ID"
												handleChange={handleChange}
												value={values.youtubeId}
											/>
											<FormHelperText>
												https://www.youtube.com/watch?v=
											</FormHelperText>
											<FormikField
												type="text"
												name="appleMusicId"
												label="Apple Music ID"
												handleChange={handleChange}
												value={values.appleMusicId}
											/>
											<FormHelperText>
												https://geo.itunes.apple.com/album/
											</FormHelperText>
										</Stack>
									</FormControl>
									<FormControl>
										<Stack spacing={4}>
											<FormSectionTitle>
												Key details
											</FormSectionTitle>

											<FormLabel>Recorded key</FormLabel>
											<Select
												value={
													values.recordedKey ||
													undefined
												}
												name="recordedKey"
												onChange={handleChange}
												placeholder="Select key"
												isRequired
											>
												{keys.map((key) => (
													<option
														selected={
															initialData.recordedKey ===
															key
														}
														value={key}
													>
														{key}
													</option>
												))}
											</Select>
											<FormLabel>
												Asaph recommends
											</FormLabel>
											<Select
												value={
													values.recommendedKey ||
													undefined
												}
												name="recommendedKey"
												onChange={handleChange}
												placeholder="Select key"
											>
												{keys.map((key) => (
													<option
														selected={
															initialData.recommendedKey ===
															key
														}
														value={key}
													>
														{key}
													</option>
												))}
											</Select>

											<FormControl>
												<FormLabel>Lyrics</FormLabel>
												<Textarea
													name="lyrics"
													onChange={handleChange}
													value={values.lyrics || ''}
												/>
											</FormControl>

											<FormControl>
												<FormLabel>
													Variations
												</FormLabel>
												<Textarea
													name="variations"
													onChange={handleChange}
													value={
														values.variations ||
														'[]'
													}
												/>
											</FormControl>

											<FormControl>
												<FormLabel>
													Analysis Output
												</FormLabel>
												<Textarea
													name="analysisOutput"
													onChange={handleChange}
													value={
														values.analysisOutput ||
														''
													}
												/>
											</FormControl>

											<FormLabel>
												Is analyzed:{' '}
												{values.analyzed?.toString()}
											</FormLabel>
										</Stack>
									</FormControl>

									<ErrorInspector
										JSON={{
											form: errors,
											request: initialData.id
												? updateSongError &&
												  updateSongError.toJSON()
												: createSongError &&
												  createSongError.toJSON(),
										}}
									/>
								</ModalBody>
								<ModalFooter>
									<ButtonGroup w="100%" spacing={2}>
										<Button type="submit">
											{initialData.id
												? 'Update'
												: 'Create'}
										</Button>
										<Button
											onClick={async () => {
												try {
													await analyzeMutateAsync({
														songId: initialData.id,
													});
													onClose();
												} catch (err) {
													console.log(err);
													alert(
														'There was an error analyzing the song, check the console for more details',
													);
												}
											}}
											isLoading={analyzeSongLoading}
										>
											Reanalyze
										</Button>
										<Button
											variant="ghost"
											mr={3}
											onClick={onClose}
										>
											Close
										</Button>
									</ButtonGroup>
								</ModalFooter>
							</form>
						);
					}}
				</Formik>
			</ModalContent>
		</Modal>
	);
};

export default CreateSongModal;
